<script lang="ts">
	import Router, { push } from "svelte-spa-router"
	import netlifyIdentity from "netlify-identity-widget"
	import Nav from "./Nav.svelte"
	import routes from "./routes"

	netlifyIdentity.init()
</script>

<Nav />
<Router {routes} />
