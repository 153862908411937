import axios from "axios";
import { user } from "../stores";
let currentUser;
user.subscribe(value => {
    currentUser = value;
});
export async function searchSteamUser(searchString) {
    const { data } = await axios.request({
        method: 'POST',
        url: '/.netlify/functions/steam-user-search',
        headers: {
            authorization: 'Bearer ' + currentUser.access_token
        },
        data: {
            searchString
        }
    });
    return data.steamUsers;
}
export async function getFriends(steamId) {
    const { data } = await axios.request({
        method: 'GET',
        url: '/.netlify/functions/steam-friends',
        params: {
            steamId,
        },
        headers: {
            authorization: 'Bearer ' + currentUser.access_token
        }
    });
    return data.friends;
}
export async function findGamesToPlay(steamIds) {
    const { data } = await axios.request({
        method: 'POST',
        url: '/.netlify/functions/steam-find-games',
        headers: {
            authorization: 'Bearer ' + currentUser.access_token
        },
        data: {
            steamIds
        }
    });
    return data.games;
}
