<script lang="ts">
  import netlifyIdentity from "netlify-identity-widget";
  import { link, push } from "svelte-spa-router";
  import { user, redirectURL } from "./stores";

  $: isLoggedIn = !!$user;

  function handleUserAction(action) {
    if (action === "login" || action === "signup") {
      netlifyIdentity.open(action);
      netlifyIdentity.on("login", (u) => {
        user.login(u);
        netlifyIdentity.close();
        if ($redirectURL !== "") {
          push($redirectURL);
          redirectURL.clearRedirectURL();
        }
      });
    } else if (action === "logout") {
      push("/");
      user.logout();
      netlifyIdentity.logout();
    }
  }
</script>

<div class="navbar navbar-expand-md navbar-light bg-light">
  <a class="navbar-brand" href="/">> vichle.se</a>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon" />
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      {#if isLoggedIn}
      <li class="nav-item dropdown">
        <a
          class="nav-link dropdown-toggle"
          href="#"
          id="navbarDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Projects
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" use:link href="/steam">Steam</a>
        </div>
      </li>
      {/if}
    </ul>
    <ul class="navbar-nav">
      <li class="nav-item">
        {#if !isLoggedIn}
        <button class="btn btn-secondary" on:click={() => handleUserAction('login')}>Log in</button>
        {:else}
        <button class="btn btn-secondary" on:click={() => handleUserAction('logout')}>Log out</button>
        {/if}
      </li>
      <li class="nav-item">
        <a class="nav-link" href="https://github.com/vichle"
          ><img src="/images/github-icon.png" alt="Github Profile" /></a
        >
      </li>
    </ul>
    <!-- <form class="form-inline my-2 my-lg-0">
      <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
      <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
    </form> -->
  </div>
</div>

<style>
  @import "/vendor/bootstrap/bootstrap.min.css";
  @import "/fonts/firacode/firacode.css";

  .nav-item img {
    height: 24px;
  }

  .navbar-brand {
    font-family: Fira Code;
  }
</style>
