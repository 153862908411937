<script lang="ts">
  import { user } from "../stores"
  import { searchSteamUser, getFriends, findGamesToPlay } from "./client"
  import type { ISteamUser, IGame } from './client'

  let searchString = ''
  let currentUser;
  let searching = false
  let error = null

  let steamUsers: ISteamUser[] = []
  let steamFriends: ISteamUser[] = []
  let gamesToPlay: IGame[] = []

  user.subscribe(value => {
    currentUser = value;
  })
</script>

<div class="container">
  <div class="row">
    <div class="col-sm form-group">
      <input class="form-control" type="text" placeholder="search" bind:value={searchString} />
      <small id="emailHelp" class="form-text text-muted"></small>
      <button type="submit" class="btn btn-primary mb-2" on:click={async () => steamUsers = await searchSteamUser(searchString)}>Search</button>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-2 p-2 card-group">
      {#each steamUsers as steamUser}
      <div class="card w-100 h-100">
        <img class="card-img-top" src={steamUser.avatarFullUrl} alt={steamUser.personaName} />
        <div class="card-body">
          <h5 class="card-title text-center">{steamUser.personaName}</h5>
          <button class="btn btn-success" on:click={async () => steamFriends = await getFriends(steamUser.id)}>Select</button>
        </div>
      </div>
      {/each}
    </div>
  </div>
  {#if steamFriends.length > 0}
  <div class="row"><h4>Friends</h4></div>
  <div class="row">
    {#each steamFriends as steamUser}
    <div class="col-sm-2 p-2 card-group">
      <div class="card w-100 {steamUser.isSelected ? 'bg-success border-success' : '' }">
        <img class="card-img-top" src={steamUser.avatarFullUrl || steamUser.avatarUrl} alt={steamUser.personaName} />
        <div class="card-body">
          <h5 class="card-title text-center">{steamUser.personaName}</h5>
          <button class="btn btn-success" on:click={() => steamUser.isSelected = !steamUser.isSelected}>Select</button>
        </div>
      </div>
    </div>
    {/each}
  </div>
  <div class="row">
    <button class="btn btn-primary" on:click={async () => gamesToPlay = await findGamesToPlay([steamUsers[0].id, ...steamFriends.filter(f => f.isSelected).map(f => f.id)])}>Find games to play</button>
  </div>
  {/if}
  {#if gamesToPlay.length > 0}
  <div class="row">
    {#each gamesToPlay as game}
    <div class="col-sm-2 p-2 card-group">
      <div class="card w-100">
        <img class="card-img-top" src={game.logoUrl} alt={game.name} />
        <div class="card-body">
          <h5 class="card-title text-center">{game.name}</h5>
        </div>
      </div>
    </div>
    {/each}
  </div>
  {/if}
</div>
